
import React from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css';


export default function SideNav(props) {

return(
<div className='sidenav'><Tooltip id="my-tooltip" />

<Sidebar  collapsedWidth="0px" collapsed={props.isSideNavCollapsed}>
  <Menu>
    <MenuItem component={<NavLink to="/" />} onClick={()=>props.toggleSideNav()}> Find apps </MenuItem>
    <MenuItem component={<NavLink to="/profile" />} onClick={()=>props.toggleSideNav()}> Profile </MenuItem>
    {props.user && props.user.isAdmin &&  
    <SubMenu label={<a data-tooltip-id="my-tooltip" data-tooltip-variant="info" data-tooltip-html="Manage your org settings <br/>and view metrics.">Admin &#9432;</a>}>
      <MenuItem component={<NavLink to="/admin/employees" />} onClick={()=>props.toggleSideNav()}>Employees</MenuItem>
      <MenuItem component={<NavLink to="/admin/utilization" />} onClick={()=>props.toggleSideNav()}>Utilization data</MenuItem>
      <MenuItem component={<NavLink to="/admin/contenthub" />} onClick={()=>props.toggleSideNav()}>Content hub</MenuItem>
      <MenuItem component={<NavLink to="/admin/settings" />} onClick={()=>props.toggleSideNav()}>Settings</MenuItem>

    </SubMenu>}
    {props.user && props.user.appOwner && 
    <SubMenu label={<a data-tooltip-id="my-tooltip" data-tooltip-variant="info" data-tooltip-html="Manage how your app displays <br/>& performs on HappMatch.">App owner &#9432;</a>}>      
      <MenuItem component={<NavLink to="/owner/users" />} onClick={()=>props.toggleSideNav()}> New users </MenuItem>
      <MenuItem component={<NavLink to="/owner/profile" />} onClick={()=>props.toggleSideNav()}> Settings </MenuItem>
      <MenuItem component={<NavLink to="/owner/performance" />} onClick={()=>props.toggleSideNav()}> Performance </MenuItem>

    </SubMenu>}
    <MenuItem component={<NavLink target="_blank" to="https://happmatch.tawk.help/" />} onClick={()=>props.toggleSideNav()}> <span style={{display:'flex',alignContent:'center'}}>Support <img width='20px' style={{paddingLeft:'4px'}}alt='external link icon' src={'/icons/external-link.png'}/></span></MenuItem>
  </Menu>
</Sidebar>
</div>

)
}