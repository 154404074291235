import React, { useEffect, Suspense, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoadingSpinner from "./Components/LoadingSpinner";
import axios from "axios";
import PrivateRoute from './Components/PrivateRoute'; // The component you just created
import './App.css';
import {isMobile} from 'react-device-detect';
import SideNav from "./Components/SideNav.jsx";
import mixpanel from 'mixpanel-browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//CONTAINERS
const Dashboard = React.lazy(() => import("./Containers/Dashboard.jsx"));
const Header = React.lazy(() => import("./Components/Header.jsx"));
const Login = React.lazy(() => import("./Containers/Login.jsx"));
const Profile = React.lazy(() => import("./Containers/Profile.jsx"));
const OrgSettings = React.lazy(() => import("./Containers/Admin/OrgSettings"));
const Employees = React.lazy(() => import("./Containers/Admin/Employees.jsx"));
const Utilization = React.lazy(() => import("./Containers/Admin/Utilization.jsx"));
const ContentHub = React.lazy(() => import("./Containers/Admin/ContentHub.jsx"));
const AppProfile = React.lazy(() => import("./Containers/AppProfile.jsx"));
const GetAppModal = React.lazy(() => import("./Components/GetAppModal.jsx"));
const OrgOnboardingModal = React.lazy(() => import("./Components/OrgOnboardingModal.jsx"));
const HappBot = React.lazy(() => import("./Containers/Admin/HappBot.jsx"));
const DISCLAIMER = React.lazy(() => import("./Containers/Legal/Disclaimer.jsx"));
const PRIVACYPOLICY = React.lazy(() => import("./Containers/Legal/PrivacyPolicy.jsx"));
const COOKIEPOLICY = React.lazy(() => import("./Containers/Legal/CookiePolicy.jsx"));
const TERMSOFSERVICE = React.lazy(() => import("./Containers/Legal/TermsofService.jsx"));
const Users = React.lazy(() => import("./Containers/AppOwner/Users.jsx"));
const Performance = React.lazy(() => import("./Containers/AppOwner/Performance.jsx"));
const AppOwnerProfile = React.lazy(() => import("./Containers/AppOwner/AppOwnerProfile.jsx"));

function App() {

  mixpanel.init("393f475d972dd9138f742a49ff5cfe53", {
    debug: false,
    ignore_dnt: true,
    track_pageview: 'url-with-path',
    persistence: "localStorage",
  });
  const [subscribedApp,setSubscribedApp] = useState(false);
  const [showGetAppModal,setShowGetAppModal] = useState(false);
  const [showOrgOnboarding,setShowOrgOnboarding] = useState(false);
  const [isSideNavCollapsed,setIsSideNavCollapsed] = useState();
  const [user,setUser] = useState();
  const [isLoggedIn,setIsLoggedIn] = useState();
  const [isLogInScreen,setIsLogInScreen] = useState();
  const [happbotSetup, setIsHappbotSetup] = useState(true);

  const notify = () => toast.error(
    <div>Activate HappBot <a href='/admin/happbot'>here</a> to onboard your team to HappMatch.</div>
    , {
    position: "bottom-left",
    autoClose: false,
    closeOnClick: false,
    progress: undefined,
    theme: "colored"
    });

    useEffect(()=>{
      if(!happbotSetup && window.location.pathname!='/admin/happbot')
        notify();
    },[happbotSetup])

const toggleGetAppModal = (app) =>{
  setSubscribedApp(app)
  setShowGetAppModal(!showGetAppModal);
 }

 const handleIsLogInScreen = (result) =>{
  setIsLogInScreen(result);
 }

 const toggleSideNav = () =>{
  if(isMobile)
  setIsSideNavCollapsed(!isSideNavCollapsed)
 }

 const toggleOrgOnboardingModal = () =>{
  setShowOrgOnboarding(!showOrgOnboarding);

 }

 const checkIfOrgComplete = () => {
  try {
      axios
        .get("/org/profile")
        .then((res) => {
          if (res.data) {
              if(!res.data.headcount){
                toggleOrgOnboardingModal();
              }
          
          }
        })
        .catch((error) => {
          console.log("Server error. Refresh and try again.");
        });
    } catch (e) {
      console.log("Server error. Refresh and try again.");
    } 
}

const getUserData = async () => {
  try {
    const res = await axios.get("/user");

    if (res.data && res.data.email) {
      mixpanel.register({
        'Email': res.data.email,
        'isAdmin': res.data.isAdmin,
        'orgSubscribed': res.data.org.subscribed,
        'isAppOwner': res.data.appOwner,
        'domain':res.data.domain
    });
    
      setUser(res.data);
      localStorage.setItem('isLoggedIn', true);
      setIsLoggedIn(true);

      if (res.data.isAdmin) {
        checkIfOrgComplete();

        if(!res.data.org.slack_enabled)
          setIsHappbotSetup(false)
      }
    } else if (res.status === 401) {
      localStorage.setItem('isLoggedIn', false);
      setIsLoggedIn(false);
    } else {
      localStorage.setItem('isLoggedIn', false);
      setIsLoggedIn(false);
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.setItem('isLoggedIn', false);
      setIsLoggedIn(false);
    } else {
      console.log("Server error. Refresh.");
    }
  }
};
 
 useEffect(() => {
   if(isMobile)
      setIsSideNavCollapsed(true);

   getUserData();
 
  }, []);



 
  return (
    <div className="App">
      <Suspense fallback={<LoadingSpinner />}>
          <Router>
      
            {!isLogInScreen && <Header toggleSideNav={toggleSideNav} isMobile={isMobile}  />}
            <GetAppModal user={user} subscribedApp={subscribedApp} showGetAppModal={showGetAppModal} toggleGetAppModal={toggleGetAppModal} />
            <OrgOnboardingModal user={user} showOrgOnboarding={showOrgOnboarding} toggleOrgOnboardingModal={toggleOrgOnboardingModal} />

            {!isLogInScreen &&<SideNav toggleSideNav={toggleSideNav} isSideNavCollapsed={isSideNavCollapsed} user={user}/>}
            {!isLogInScreen && <ToastContainer autoClose={false} />}

            <div className='page-container'>

            <Routes>
              {/* PUBLIC ROUTES */}
              <Route path="/login" element={<Login handleIsLogInScreen={handleIsLogInScreen} isLoggedIn={isLoggedIn}  />} />
              <Route path="/policies/disclaimer" element={<DISCLAIMER />} />
              <Route path="/policies/privacypolicy" element={<PRIVACYPOLICY />} />
              <Route path="/policies/cookiepolicy" element={<COOKIEPOLICY />} />
              <Route path="/policies/tos" element={<TERMSOFSERVICE />} />

              {/* PRIVATE ROUTES */}
              <Route path="/" element={<PrivateRoute><Dashboard toggleGetAppModal={toggleGetAppModal} user={user} /></PrivateRoute>} />
              <Route path="/profile" element={<PrivateRoute><Profile user={user}  /></PrivateRoute>} />
              <Route path="/app/*" element={<PrivateRoute><AppProfile user={user} toggleGetAppModal={toggleGetAppModal} /></PrivateRoute>} />
              <Route path="/admin/settings" element={<PrivateRoute><OrgSettings user={user}/></PrivateRoute>} />
              <Route path="/admin/utilization" element={<PrivateRoute><Utilization user={user} /></PrivateRoute>} />
              <Route path="/admin/happbot" element={<PrivateRoute><HappBot user={user} /></PrivateRoute>} />
              <Route path="/admin/employees" element={<PrivateRoute><Employees user={user} /></PrivateRoute>} />
              <Route path="/admin/contenthub" element={<PrivateRoute><ContentHub user={user} /></PrivateRoute>} />
              <Route path="/owner/profile" element={<PrivateRoute><AppOwnerProfile user={user}/></PrivateRoute>} />
              <Route path="/owner/users" element={<PrivateRoute><Users user={user} /></PrivateRoute>} />
              <Route path="/owner/performance" element={<PrivateRoute><Performance user={user} /></PrivateRoute>} />

            </Routes>
            </div>
          </Router>
          
        </Suspense>
    </div>
  );
}

export default App;
